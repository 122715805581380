<template>
    <Layout title="课程列表">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="名称" prop="keyWord">
                    <el-input v-model="queryForm.keyWord" />
                </el-form-item>
                <el-form-item label="举办单位" prop="organizer">
                    <el-select v-model="queryForm.organizer">
                        <el-option
                            v-for="item in optionsData.organizerList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="培训类型" prop="training">
                    <el-select v-model="queryForm.training">
                        <el-option
                            v-for="item in optionsData.trainingTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="课程类型" prop="cat">
                    <el-select v-model="queryForm.cat">
                        <el-option
                            v-for="item in optionsData.catList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="queryForm.status">
                        <el-option label="全部" value="" />
                        <el-option label="已发布" :value="1" />
                        <el-option label="未发布" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-plus" size="small" @click="handleCreate">添加课程</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="name" label="课程名称" min-width="200"></el-table-column>   
                <el-table-column prop="courseId" label="课程ID" align="center"></el-table-column>
                <el-table-column label="图片" align="center" width="120">
                    <template slot-scope="scope">
                        <img :src="scope.row.cover" width="80" height="80" />
                    </template>
                </el-table-column>
                <el-table-column label="课程类型" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.trainingType === 0 ? '线上课程' : '线下课程' }}
                    </template>                    
                </el-table-column>              
                <el-table-column prop="organizerString" label="举办单位" min-width="180"></el-table-column>   
                <el-table-column label="热门课程" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.status ? 'success' : ''">{{ scope.row.status ? '是' : '否' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否发布" align="center">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#999"
                            :active-value="1"
                            :inactive-value="0"
                            @change="onStatusChange($event, scope.row.courseId)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="prizeName" label="抽奖活动" width="180" align="center"></el-table-column> 
                <el-table-column prop="createTimeString" label="创建时间" width="180"></el-table-column>         
                <el-table-column fixed="right" label="操作" width="380" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini" @click.native="handleEdit(scope.row)">编辑</el-button>
                            <el-button type="primary" size="mini" @click.native="handleEditCourseAttachment(scope.row)">课件信息</el-button>
                            <el-button type="primary" size="mini" @click.native="handleSetCourseExam(scope.row)">考题设置</el-button>
                            <el-button type="success" size="mini" @click.native="handleSetPrize(scope.row)">设置抽奖</el-button>
                            <el-button type="danger" size="mini" @click.native="handleRemove(scope.row)">删除</el-button>   
                        </el-button-group>                                            
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <CourseAttachment v-if="loadCourseAttachment" />

        <SetPrize v-if="loadSetPrize" />
    </Layout>
</template>

<script>
import { 
    getCourseListApi, 
    publishCourseApi, 
    unPublishCourseApi,
    deleteCourseApi 
} from '@/api/course';

import CourseAttachment from './components/CourseAttachment';
import SetPrize from './components/SetPrize';

export default {
    components: {
        CourseAttachment,
        SetPrize
    },

    data() {
        return {
            queryOptions: {},

            queryForm: {
                pageSize: 10,
                currentPage: 1,
                keyWord: '',
                organizer: '',
                training: '',
                cat: '',
                status: ''

            },

            loading: false,

            tableData: [],
            totalPage: 1,

            loadCourseAttachment: false,
            loadSetPrize: false,
        };
    },

    computed: {
        optionsData() {
            return this.$store.state.course.optionsData;
        }
    },

    methods: {
        // 获取数据
        getData() {
            getCourseListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;
                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 更新发布状态
        onStatusChange(e, id) {
            if (e === 1) {
                publishCourseApi({ courseId: id }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '发布成功！'
                    });
                });
            } else {
                unPublishCourseApi({ courseId: id }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '取消发布成功！'
                    });
                });
            }            
        },

        // 添加课程
        handleCreate() {
            this.$router.push({
                path: '/course/modify'
            });
        },

        // 附件信息
        handleEditCourseAttachment(rowData) {
            const { courseId } = rowData;
            this.loadCourseAttachment = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_COURSE_ATTACHMENT_DIALOG', { courseId });
            });
        },

        // 编辑
        handleEdit(rowData) {
            const { courseId } = rowData;
            this.$router.push({
                path: '/course/modify',
                query: {
                    courseId
                }
            });
        },

        // 设置考题
        handleSetCourseExam(rowData) {
            const { courseId } = rowData;
            this.$router.push({
                path: '/course/exam',
                query: {
                    courseId
                }
            });
        },

        // 设置抽奖
        handleSetPrize(rowData) {
            this.loadSetPrize = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_SET_PRIZE_DIALOG', rowData);
            });
        },

        // 删除
        handleRemove(rowData) {
            const { courseId } = rowData;
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCourseApi({ courseId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    }); 
                    this.getData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.$store.dispatch('course/getCourseOptions');

        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
